<script>
	import EmailPreview from '@/lib/EmailPreview.svelte'
	import Field from './Field.svelte'
	import { put } from '@/service/api.js'
	export let village
	export let original

	const searchFields = [
		{
			label: 'Name',
			key: 'name',
			help: 'This should be the name that your community uses, so they will recognize it in emails and texts.',
		},
		{
			label: 'State',
			key: 'searchState',
			help: 'Please use the abbreviation, for example "WY" for Wyoming.',
		},
		{
			label: 'Zip Code',
			key: 'searchZip',
			help: 'If your community is spread across multiple zip codes, use the one that the majority reside in.',
		},
	]
	const emailFields = [
		{
			label: 'Line',
			key: 'addressLine',
		},
		{
			label: 'City',
			key: 'city',
		},
		{
			label: 'State',
			key: 'state',
		},
		{
			label: 'Zip Code',
			key: 'zip',
		},
		{
			label: 'Note',
			key: 'emailNote',
			help: 'Optional note to put before the address information in the email, like additional contact details or a friendly greeting.',
		},
	]

	$: changes = [ ...searchFields, ...emailFields ].find(({ key }) => (village[key] || '') !== (original[key] || ''))

	let saving
	let success
	let error
	const save = () => {
		saving = true
		success = null
		put('/api/village', { body: village })
			.then(() => {
				success = true
				saving = false
			})
			.catch(({ body: { error: e } }) => {
				error = e
				saving = false
			})
	}
</script>

<div class="container">
	<h1>Your Village</h1>
	<hr>
	<h3>Community Identity</h3>
	<p>
		These fields are used to help your community find you.
	</p>
	{#each searchFields as { label, key, help }}
		<Field
			{label}
			{key}
			{help}
			disabled={saving}
			bind:form={village}
			on:input={() => { success = null }}
		/>
	{/each}
	<hr>
	<h3>Legal Address</h3>
	<p>
		This address information will be used at the bottom of emails, it is
		required by federal anti-spam laws.
	</p>
	<p>
		It should be the address of something like a town hall, or whatever
		operating address that you use on legal documents.
	</p>
	{#each emailFields as { label, key, help }}
		<Field
			{label}
			{key}
			{help}
			disabled={saving}
			bind:form={village}
			on:input={() => { success = null }}
		/>
	{/each}
	<p>Here's what an example email message would look like:</p>
	<EmailPreview
		{village}
		message="Friendly reminder that the electric outage will start at 3pm today. Repairs should be done by 5pm."
	/>
	<hr>
	<div class="row align-items-center">
		<div class="col-auto">
			<button
				class="btn btn-primary"
				disabled={saving || !changes}
				on:click={save}
			>
				Save Changes
			</button>
		</div>
		<div class="col">
			{#if !changes && !success}
				<span class="text-muted">No changes to save.</span>
			{:else if success}
				Changes saved.
			{/if}
		</div>
	</div>
	{#if error}
		<div class="alert alert-danger mt-3">
			An error happened when saving. You should reload the page
			and try again, but if the error keeps happening, please email
			<a href="mailto:office@villagesignal.com">office@villagesignal.com</a>
			with these details:
			<br><br>
			Status: {error.status}
			<br>
			Code: {error.code}
			<br>
			Title: {error.title}
			<br>
			Details: {error.detail}
		</div>
	{/if}
</div>
