import { get } from '@/service/api.js'
import template from './+page.svelte'
export default {
	template,
	resolve: async () => {
		const { body: { data: people } } = await get('/api/people')
		return {
			people,
		}
	},
}
