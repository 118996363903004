import { router, previousRoutes } from '@/service/router.js'
import { startPageTransition, endPageTransition } from '@/lib/load-cache.js'

import login from '@/route/login/+page.js'
import app from '@/route/app/+page.js'
import addPerson from '@/route/app/addPerson/+page.js'
import editPerson from '@/route/app/editPerson/+page.js'
import messages from '@/route/app/messages/+page.js'
import people from '@/route/app/people/+page.js'
import sendMessage from '@/route/app/sendMessage/+page.js'
import village from '@/route/app/village/+page.js'

const routes = [
	[ 'login', '/login', login ],
	[ 'app', '/app', app ],
	[ 'app.addPerson', '/addPerson', addPerson ],
	[ 'app.editPerson', '/editPerson/:id', editPerson ],
	[ 'app.messages', '/messages', messages ],
	[ 'app.people', '/people', people ],
	[ 'app.sendMessage', '/sendMessage', sendMessage ],
	[ 'app.village', '/village', village ],
]

for (const [ name, route, state ] of routes) {
	state.name = name
	state.route = route
	router.addState(state)
}

const stateChangeHistory = []

router.on('routeNotFound', (...args) => { console.error('routeNotFound', ...args) })
router.on('stateError', error => { console.error('stateError', error) })
router.on('stateChangeError', error => {
	console.error('stateChangeError', error)
	if (error.status === 403 || error.status === 401) {
		const params = {}
		const previousState = stateChangeHistory[stateChangeHistory.length - 1]
		if (previousState && !(previousState.state.name === 'app.dashboard' && !Object.keys(previousState.parameters).length)) {
			params.goto = JSON.stringify({
				name: previousState.state.name,
				params: previousState.parameters,
			})
		}
		router.go('login', params)
	} else console.error('main:stateChangeError', error)
})

router.on('stateChangeEnd', () => {
	endPageTransition()
	previousRoutes.set(stateChangeHistory)
	// progress.complete()
	// if (state.data && state.data.title) window.document.title = `MCS OnTrack | ${state.data.title}`
})

router.on('stateChangeStart', (state, parameters) => {
	startPageTransition()
	stateChangeHistory.push({ state, parameters })
	window.scrollTo(0, 0)
})

console.log('Starting app...')
router.evaluateCurrentRoute('login')
