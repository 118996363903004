<script>
	import { router } from '@/service/router.js'
	import Person from './Person.svelte'
	export let people

	const remove = person => () => {
		people = [ ...people.filter(p => p.id !== person.id) ]
	}
</script>

<div class="container">
	<div class="card mb-3">
		<div class="card-body">
			<div class="row">
				<div class="col">
					Want to add a person?
				</div>
				<div class="col-auto">
					<a href={router.makePath('app.addPerson')} class="btn btn-sm btn-primary">
						Start Here
					</a>
				</div>
			</div>
			{#if !people.length}
				(You can add yourself!)
			{/if}
		</div>
	</div>
	{#if people.length}
		<h2>People</h2>
		{#each people as person (person.id)}
			<Person {person} on:remove={remove(person)} />
		{/each}
	{/if}
</div>
