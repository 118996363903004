<script>
	export let message
	export let error
</script>

<div class="alert alert-danger mt-3">
	{message || 'An error happened!'}
	You should reload the page and try again, but if the error keeps happening, please email
	<a href="mailto:office@villagesignal.com">office@villagesignal.com</a>
	with these details:
	<br><br>
	Status: {error.status}
	<br>
	Code: {error.code}
	<br>
	Title: {error.title}
	<br>
	Details: {error.detail}
</div>
