<script>
	import { createEventDispatcher } from 'svelte'
	import { router } from '@/service/router.js'
	import { del } from '@/service/api.js'

	export let person

	const dispatch = createEventDispatcher()

	let confirm
	let disabled
	const remove = () => {
		disabled = true
		del(`/api/person?id=${person.id}`)
			.then(() => {
				dispatch('remove')
			})
	}
</script>

<style>
	.buttons-column {
		width: 7em;
	}
</style>

<div class="card">
	<div class="card-body">
		<div class="row">
			<div class="col">
				{#if person.name}
					<h5 class="card-title">{person.name}</h5>
				{/if}
				{#if person.email}
					<p class="mb-0">{person.email}</p>
				{/if}
				{#if person.phone}
					<p class="mb-0">{person.phone}</p>
				{/if}
			</div>
			<div class="col-auto buttons-column">
				<div class="d-grid gap-0">
					<a class:disabled class="btn btn-sm btn-block btn-outline-primary" href={router.makePath('app.editPerson', { id: person.id })}>
						Edit
					</a>
					{#if confirm}
						<button {disabled} class="btn btn-sm btn-outline-secondary mt-2" on:click={() => { confirm = false }}>
							Cancel
						</button>
					{:else}
						<button {disabled} class="btn btn-sm btn-outline-warning mt-2" on:click={() => { confirm = true }}>
							Remove
						</button>
					{/if}
				</div>
			</div>
		</div>
		{#if confirm}
			<div class="alert alert-secondary mt-2">
				<div class="row">
					<div class="col">
						Are you sure you want to remove this person? They can be added again later.
					</div>
					<div class="col-auto">
						<button {disabled} class="btn btn-sm btn-outline-danger" on:click={remove}>
							Yes, Remove
						</button>
					</div>
				</div>
			</div>
		{/if}
	</div>
</div>

