<script>
	import { onMount } from 'svelte'
	import { removeHtml } from '@/shared/strings.js'

	export let village
	export let message
	$: cleanMessage = removeHtml(message || '')

	let box
	let width
	onMount(() => {
		width = box?.offsetWidth || 500
	})
	$: paddingTop = width >= 500
		? '165px'
		: '33%'
	$: paddingLeft = width >= 500
		? '70px'
		: '14%'
</script>

<style>
	.preview-box {
		padding-right: 25px;
		padding-bottom: 30px;
		max-width: 500px;
		background-image: url("/email-preview.png");
		background-size: 100% auto;
		max-height: 450px;
		overflow: hidden;
	}
	.notification-preview {
		font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
		font-size: 0.8em;
	}
	.notification-preview.blank {
		padding: 1em;
		border-radius: 5px;
		text-align: center;
		background-color: #ffd983;
	}
</style>

<svelte:window on:resize={() => { width = box?.offsetWidth }}/>

<div class="preview-box" bind:this={box} style:padding-top={paddingTop} style:padding-left={paddingLeft}>
	<div class="notification-preview" class:blank={!cleanMessage}>
		{cleanMessage || 'Add a message to preview it here.'}
		{#if cleanMessage}
			<br>
			&mdash;{village.name || 'Village Signal'}
			<br>
			<br>
			<small>
				{#if village.emailNote}
					{village.emailNote}
					<br>
				{/if}
				{village.addressLine || 'Your Address'}
				|
				{[ village.city || 'Nearby City', village.state || 'WY' ].join(', ')}
				{village.zip || '12345'}
				{#if village.name}
					<hr>
					Sent by Village Signal, on behalf of {village.name}. If you believe this
					email is spam or contains illegal information, please forward it to: office@villagesignal.com
				{/if}
			</small>
		{/if}
	</div>
</div>
