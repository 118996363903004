<script>
	import { onMount } from 'svelte'
	import { get, post } from '@/service/api.js'
	import { set } from '@/service/localstorage.js'
	import { router, previousRoutes } from '@/service/router.js'

	// Note: this gets replaced at build time with the Rollup replace plugin.
	const turnstileSiteKey = 'TURNSTILE_SIGNUP_SITE_KEY'

	let turnstileResponse = ''
	onMount(() => {
		window.turnstile.ready(() => {
			window.turnstile.render('#cf-turnstile', {
				sitekey: turnstileSiteKey,
				callback: token => { turnstileResponse = token },
			})
		})
	})

	export let params

	let email = ''
	let code = ''
	let rememberMe
	let requestInFlight
	let sessionToken
	let userCreated
	let maybeBadCode
	const login = () => {
		requestInFlight = true
		post('/api/login/start', { body: { email, turnstileResponse } })
			.then(response => {
				sessionToken = response.body?.data?.token
				userCreated = response.body?.data?.userCreated
				if (sessionToken) {
					set('session', sessionToken)
					requestInFlight = false
				}
			})
	}
	let retries = 0
	const waitForIt = () => {
		get('/api/me')
			.then(() => {
				let where = { name: 'app' }
				if (params.goto) {
					try {
						const goto = JSON.parse(params.goto)
						if (goto.name) where = goto
					} catch (ignore) {
						//
					}
				}
				const { name, ...more } = where
				router.go(name, more, { inherit: false })
			})
			.catch(() => {
				retries++
				if (retries < 60) setTimeout(waitForIt, 1000)
			})
	}
	const finish = () => {
		if (code) {
			maybeBadCode = false
			requestInFlight = true
			post('/api/login/finish', {
				body: { code },
				headers: { authorization: `Basic ${sessionToken}` },
			})
				.then(response => {
					if (response.body?.data?.valid) waitForIt()
				})
				.catch(() => {
					maybeBadCode = true
					requestInFlight = false
				})
		}
	}
</script>

<style>
	img {
		max-width: 8em;
	}
	:global(.cf-turnstile iframe) {
		margin-bottom: 3rem;
		width: 100% !important;
	}
</style>

<div class="container">
	{#if $previousRoutes?.find(r => r.state.name !== 'login')}
		<div class="alert alert-success mt-3">
			You are now logged out.
		</div>
	{/if}
	<div class="text-center my-5">
		<p>
			<img width="120" src="https://villagesignal.com/favicon.svg" alt="📜">
		</p>
		<h1>Village Signal</h1>
	</div>
	<div class="row">
		<div class="col-md-8 col-lg-6 col-xl-4 offset-md-2 offset-lg-3 offset-xl-4">
			<main class="form-signin w-100 m-auto">
				{#if !sessionToken}
					<form on:submit|preventDefault={login}>
						<div class="mb-5">
							<label for="email" class="form-label">
								Sign in to your My Village app by first entering your email address:
							</label>
							<input type="email" class="form-control" id="email" bind:value={email} disabled={requestInFlight}>
						</div>
						<div class="checkbox mb-5">
							<label>
								<input type="checkbox" bind:value={rememberMe}>
								If you are on your own computer or phone, check this box to stay logged in longer.
							</label>
						</div>
						<div
							class="cf-turnstile"
							id="cf-turnstile"
							data-sitekey={turnstileSiteKey}
							data-callback="javascriptCallback"
						></div>
						<button class="w-100 btn btn-lg btn-primary" type="submit" disabled={!email.length || requestInFlight}>
							Send a login link to my email
						</button>
					</form>
				{:else}
					<form on:submit|preventDefault={finish}>
						<div class="mb-5">
							<label for="code" class="form-label">
								Great! Now check your email and type the code in here to finish logging in:
							</label>
							<input type="text" class="form-control" id="code" bind:value={code} disabled={requestInFlight}>
							{#if maybeBadCode}
								<div class="invalid-feedback" style="display: block;">
									The code was not correct, or it may have expired. <br> Make sure you typed it correctly, or
									<a href="/">start over</a>.
								</div>
							{/if}
						</div>
						<button class="w-100 btn btn-lg btn-primary" type="submit" disabled={!code.length || requestInFlight}>
							Finish logging in
						</button>
					</form>
				{/if}
				<p class="mt-5 mb-3 text-muted">© 2022–{new Date().toISOString().split('-')[0]}</p>
			</main>
		</div>
	</div>
</div>
