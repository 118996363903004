<script>
	export let key
	export let label
	export let help
	export let disabled
	export let form
</script>

<div class="row mb-3">
	<div class="col-3 text-end pt-1">
		<label for={key} class="form-label">
			{label}
		</label>
	</div>
	<div class="col-7">
		<input
			{disabled}
			type="text"
			class="form-control"
			id={key}
			bind:value={form[key]}
			on:input
		>
		{#if help}
			<div class="form-text">
				{help}
			</div>
		{/if}
	</div>
</div>
