import { get } from '@/service/api.js'

let promises = {}

export const getCurrentUser = async () => {
	if (transition) {
		if (!promises.currentUser) promises.currentUser = get('/api/me').then(res => res.body.data)
		return promises.currentUser
	}
	return get('/api/me').then(res => res.body.data)
}

export const getVillage = async () => {
	if (transition) {
		if (!promises.village) promises.village = get('/api/village').then(res => res.body.data)
		return promises.village
	}
	return get('/api/village').then(res => res.body.data)
}

export const getMessageCount = async () => {
	if (transition) {
		if (!promises.messageCount) promises.messageCount = get('/api/messageCount').then(res => res.body.data)
		return promises.messageCount
	}
	return get('/api/messageCount').then(res => res.body.data)
}

export const getPeopleCount = async () => {
	if (transition) {
		if (!promises.peopleCount) promises.peopleCount = get('/api/peopleCount').then(res => res.body.data)
		return promises.peopleCount
	}
	return get('/api/peopleCount').then(res => res.body.data)
}

let transition
export const startPageTransition = () => {
	transition = true
	promises = {}
}
export const endPageTransition = () => {
	transition = false
}
