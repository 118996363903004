<script>
	import { onMount } from 'svelte'
	import { removeHtml } from '@/shared/strings.js'

	export let message
	$: cleanMessage = removeHtml(message || '')

	let box
	let width
	onMount(() => {
		width = box?.offsetWidth || 500
	})
	$: paddingTop = width >= 500
		? '85px'
		: '18%'
	$: paddingX = width >= 500
		? '70px'
		: '12%'
</script>

<style>
	.preview-box {
		padding-bottom: 20px;
		max-width: 500px;
		background-image: url("/phone-preview.png");
		background-size: 100% auto;
		max-height: 250px;
		overflow: hidden;
	}
	.notification-preview {
		background-color: rgba(221, 221, 221, 0.75);
		border-radius: 5px;
		padding: 8px 12px;
		font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
		font-size: 0.8em;
	}
	.notification-preview.blank {
		text-align: center;
		background-color: #ffd983;
	}
</style>

<svelte:window on:resize={() => { width = box?.offsetWidth }}/>

<div class="preview-box" style:padding-left={paddingX} style:padding-right={paddingX} style:padding-top={paddingTop} bind:this={box}>
	<div class="notification-preview d-flex align-items-center" class:blank={!cleanMessage}>
		{#if cleanMessage}
			<div class="icon pe-3 fs-5">
				💬
			</div>
		{/if}
		<div class="message">
			{#if cleanMessage}
				<div class="top">
					<strong>(844) 913-1854</strong>
				</div>
			{/if}
			{cleanMessage || 'Add a message to preview it here.'}
		</div>
	</div>
</div>
