<script>
	import { onMount } from 'svelte'
	import OnboardingAddPeople from '@/lib/OnboardingAddPeople.svelte'
	import OnboardingSetupVillage from '@/lib/OnboardingSetupVillage.svelte'
	import ErrorAlert from '@/lib/ErrorAlert.svelte'
	import EmailPreview from '@/lib/EmailPreview.svelte'
	import TextPreview from '@/lib/TextPreview.svelte'
	import { villageCanSend } from '@/shared/village-can-send.js'
	import { router } from '@/service/router.js'
	import { post } from '@/service/api.js'

	// Note: this gets replaced at build time with the Rollup replace plugin.
	const turnstileSiteKey = 'TURNSTILE_MESSAGE_SITE_KEY'

	let turnstileResponse = ''
	onMount(() => {
		window.turnstile.ready(() => {
			window.turnstile.render('#cf-turnstile', {
				sitekey: turnstileSiteKey,
				callback: token => { turnstileResponse = token },
			})
		})
	})

	export let messageCount
	export let peopleCount
	export let village

	let message = ''
	let checked
	let sending
	let success
	let error
	const send = () => {
		sending = true
		success = null
		post('/api/messages', { body: { message, checked, turnstileResponse } })
			.then(() => {
				sending = false
				success = true
			})
			.catch(e => {
				error = e.body.error
				sending = false
			})
	}
</script>

<div class="container">
	{#if !villageCanSend(village)}
		<OnboardingSetupVillage />
	{:else if !peopleCount}
		<OnboardingAddPeople {messageCount} {peopleCount} />
	{:else}
		<h1>
			Send
			{#if messageCount}
				a
			{:else}
				your first
			{/if}
			message
		</h1>

		<label for="message" class="form-label">
			Type your message here:
		</label>
		<textarea
			class="form-control"
			id="message"
			rows="6"
			bind:value={message}
			disabled={sending || success}
		></textarea>
		<hr>
		<h3>Text Preview</h3>
		<TextPreview {message} />
		<hr>
		<h3>Email Preview</h3>
		<EmailPreview {message} {village} />
		<hr>
		{#if success}
			<div class="alert alert-success mb-3">
				Message successfully sent to
				{peopleCount}
				{peopleCount > 1 ? 'people' : 'person'}.
				You could go to the
				<a href={router.makePath('app.messages')}>Messages</a>
				list now, if you want.
			</div>
		{:else}
			<div class="alert alert-info mb-3">
				This message will be sent to
				{peopleCount}
				{peopleCount > 1 ? 'people' : 'person'}.
			</div>
			<div class="mb-3 form-check">
				<input
					type="checkbox"
					class="form-check-input"
					id="confirmation"
					bind:checked
					disabled={sending || success}
				>
				<label class="form-check-label" for="confirmation">
					I'm ready to send!
				</label>
			</div>
			<div
				class="cf-turnstile"
				id="cf-turnstile"
				data-sitekey={turnstileSiteKey}
				data-callback="javascriptCallback"
			></div>
			<button
				class="btn btn-primary"
				disabled={!checked || !message || sending || success}
				on:click={send}
			>
				Send Message
			</button>
		{/if}
	{/if}

	{#if error}
		<ErrorAlert {error} message="An error happened while sending the message." />
	{/if}
</div>
