import { getVillage } from '@/lib/load-cache.js'
import { get } from '@/service/api.js'
import template from './+page.svelte'
export default {
	template,
	resolve: async () => {
		const [
			{ body: { data: messages } },
			village,
		] = await Promise.all([
			get('/api/messages'),
			getVillage(),
		])
		return {
			messages,
			village,
		}
	},
}
