import template from './+page.svelte'
import { getCurrentUser, getMessageCount, getPeopleCount, getVillage } from '@/lib/load-cache.js'

export default {
	template,
	defaultChild: 'messages',
	resolve: async () => {
		const [
			currentUser,
			messageCount,
			peopleCount,
			village,
		] = await Promise.all([
			getCurrentUser(),
			getMessageCount(),
			getPeopleCount(),
			getVillage(),
		])
		return {
			currentUser,
			messageCount,
			peopleCount,
			village,
		}
	},
}
